import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toggle-wr", [ $props.toggleClass ]])
  }, [
    _createElementVNode("label", {
      class: _normalizeClass([ { disabled: $props.disabled }, $props.labelBold, $props.labelClass, $props.labelHidden ])
    }, [
      _createTextVNode(_toDisplayString($props.label), 1),
      _createElementVNode("input", {
        type: "checkbox",
        value: $props.modelValue,
        checked: $props.modelValue,
        onChange: $setup.toggleSelection,
        disabled: $props.disabled
      }, null, 40, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(["slider", [ $props.additionalClass ]])
      }, null, 2)
    ], 2)
  ], 2))
}